const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Mat White Portfolio', // Navigation and Site Title
  siteTitleAlt: 'Mat White', // Alternative Site title for SEO
  siteTitleShort: 'Mat White', // short_name for manifest
  siteHeadline: 'Brief overview of my dev experience', // Headline for schema.org JSONLD
  siteUrl: 'https://matwhite.info', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Brief overview of my dev experience',
  author: 'matius_uk', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@cara', // Twitter Username
  // ogSiteName: 'cara', // Facebook Site Name
  // ogLanguage: 'en_UK', // Facebook Language
  // googleAnalyticsID: 'UA-47519312-5',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
}
