import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import { Parallax } from "react-spring/renderprops-addons.cjs";

// Components
import Layout from "../components/Layout";
import ProjectCard from "../components/ProjectCard";

// Elements
import Inner from "../elements/Inner";
import { Title, BigTitle, Subtitle } from "../elements/Titles";

// Views
import Hero from "../views/Hero";
import Projects from "../views/Projects";
import About from "../views/About";
import Contact from "../views/Contact";

import avatar from "../images/avatar.jpg";

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

const ContentWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr;
  @media (max-width: 1200px) {
    grid-gap: 1rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`;

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`;

const AboutSub = styled.p`
  ${tw`text-white pt-12 sm:pt-0 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`;

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`;

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`;

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0} factor={1}>
        <BigTitle>Mat White</BigTitle>
        <Subtitle>
          Front end web developer with a broad range of experience, good
          initiative and a resourceful team member.
        </Subtitle>
      </Hero>
      <ContentWrapper>
        <Projects offset={1} factor={1}>
          <ProjectsWrapper>
            <Title>Projects</Title>
            <ProjectCard
              title="Nursing Tools"
              link="https://nursing-tools.com"
              bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
            >
              This little app helps healthcare professionals by simplifying
              regular calculations.
            </ProjectCard>
            <ProjectCard
              title="LED ROI"
              link="https://ledroicalc.com"
              bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
            >
              LED lighting return on investment calculator.
            </ProjectCard>
            <ProjectCard
              title="iOS 11 Calculator"
              link="https://codepen.io/matius_uk/full/XNxjoP"
              bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
            >
              Recreation of the iOS 11 calculator.
            </ProjectCard>
            <ProjectCard
              title="Pomodoro Timer"
              link="https://codepen.io/matius_uk/full/KmdePJ"
              bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
            >
              A Star Wars themed pomodoro timer.
            </ProjectCard>
          </ProjectsWrapper>
        </Projects>
      </ContentWrapper>
      <ContentWrapper>
        <About offset={2} factor={2.5}>
          <Title>About</Title>
          <AboutHero>
            <Avatar src={avatar} alt="Mat White" />
            <AboutSub>
              An experienced IT professional, with a good eye for design and a
              solid understanding of JavaScript. I have great communication
              skills, an eye for detail and love clean, readable code.
            </AboutSub>
          </AboutHero>
          <AboutDesc>
            I’m a front end developer, who believes that the key to bettering
            myself and therefore my ability to contribute, is through constant
            learning and always being excited to discover new opportunities that
            expand my knowledge.
          </AboutDesc>
        </About>
      </ContentWrapper>
      <Contact offset={4} factor={1}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            Ping me a <a href="mailto:matius_uk@yahoo.co.uk">message</a> or find
            me on other platform(s):{" "}
            <a href="https://www.instagram.com/matius_uk/" target="_blank">
              Instagram
            </a>
          </ContactText>
        </Inner>
      </Contact>
    </Parallax>
  </>
);

export default Index;
